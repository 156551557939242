.dx-fileuploader-files-container {
  display: none;
}

.wrapper-popup-waiting {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .title {
    text-align: center;
    font-size: 20px;
  }
}

.popup-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;

  .title {
    text-align: center;
    font-size: 20px;
    margin: 0;
  }

  .card {
    width: 100%;
    background-color: rgba(250, 82, 82, 0.1);
    border: 1px solid #fa5252;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    ul > li {
      color: #fa5252;
    }
  }
}

.card-reference-number {
  width: 100%;
  background-color: rgb(248, 248, 248);
  border-radius: 10px;
  margin-bottom: 5px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.reject-reason input {
  background-color: rgba(250, 82, 82, 0.2);
}

.popup-contact-activity {
  width: 40%;
  height: auto;
}

@media screen and (max-width: 480px) {
  .popup-contact-activity {
    width: auto;
    height: auto;
  }
}
