.dx-field-item .dx-toggle-controls-paddings {
  padding: 10px 0 0;
}
.dx-field-item .dx-toggle-controls-paddings {
  padding-inline-start: 0px !important;
}

.dx-card h3 {
  margin: 0;
  padding: 7px 0;
  font-size: 16px !important;
  width: 70%;
  position: relative;
}

.dx-fileuploader-files-container {
  display: none;
}

.btn-flex {
  display: flex;
  justify-content: space-between;
}

.title-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-detail > div {
  margin-right: 15px;
}

.wrappedColumnClass {
  white-space: normal;
  word-wrap: break-word;
}

.title {
  font-size: 20px !important;
  margin: 0;
  font-weight: 500 !important;
}

.wrapper-popup-reminder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.card {
  width: 100%;
  background-color: rgba(250, 82, 82, 0.1);
  border: 1px solid #fa5252;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  ul > li {
    color: #fa5252;
    text-align: left;
  }
}

.preview-file-pdf {
  width: 350px;
}

@media (max-width: 400px) {
  .preview-file-pdf {
    width: 100%;
  }
}
