.status-app {
  display: inline;
  padding: 3px 7px;
  border-radius: 13px;

  span {
    font-size: 13px;
  }
}

.status-approved {
  background: #10d710;
  color: #ffffff;
}

.status-rejected {
  background: #d71010;
  color: #ffffff;
}

.status-unsigned {
  background: #d77310;
  color: #ffffff;
}
.status-onverifcation{
  background: #d7a910;
  color: #ffffff;
}
.status-incomplete{
  background: #707070;
  color: #ffffff;
}

.status-signed {
  background: #91d710;
  color: #ffffff;
}
.status-discarded {
  background: #8e10d7;
  color: #ffffff;
}
.status-completed {
  background: #1048d7;
  color: #ffffff;
}
.status-disbursementfailed {
  background: #d74510;
  color: #ffffff;
}

.status-canceled {

}
.status-toverify {
  background: #cad710;
  color: #ffffff;
}
.status-unsignedchanges {
  background: #d78110;
  color: #ffffff;
}
